import { action, makeObservable, observable, runInAction } from 'mobx';
import { NavBar } from "../api/generated";
import { IRootStore } from './RootStore';
import { api } from '../api';

export class NavBarStore {
    @observable navBarViewModel: NavBar = {
        Language: '',
        NavbarAbout: '',
        NavbarContact: '',
        NavbarHome: '',
        NavbarProject: '',
    }
    rootStore: IRootStore
   
    constructor(rootStore: IRootStore) {
        makeObservable(this)
        this.rootStore = rootStore;
    }

    @action.bound
    async fetchNavBarViewModel(Language: string): Promise<void> {
        try {
            let response = await api.componentsSchemaLanguageGet(Language, 3)
            runInAction(()=>{
                this.navBarViewModel = response.data
            })
        } catch {

        }
    }
}