import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import ImageGallery from '../components/ImageGallery';
import { rootStore } from '../stores/RootStore';

const Projects = () => {
  const { fetchProjects, projects } = rootStore.projectStore

  useEffect(() => {
    fetchProjects()
  }, [])

  return (
    <motion.div 
      className="pt-[8vh]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-wrap gap-2 h-screen">
        <ImageGallery projects={projects} />
      </div>
    </motion.div>
  );
};

export default inject('rootStore')(observer(Projects));
