/* tslint:disable */
/* eslint-disable */
/**
 * Gateway API
 * API for bookmarks
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AboutUsComponent
 */
export interface AboutUsComponent {
    /**
     * 
     * @type {string}
     * @memberof AboutUsComponent
     */
    'Language'?: string;
    /**
     * 
     * @type {string}
     * @memberof AboutUsComponent
     */
    'Val'?: string;
}
/**
 * @type ComponentsSchemaImageLanguagePostRequest
 * @export
 */
export type ComponentsSchemaImageLanguagePostRequest = HomeDetail;

/**
 * @type ComponentsSchemaLanguageGet200Response
 * @export
 */
export type ComponentsSchemaLanguageGet200Response = AboutUsComponent | HomeDetail | NavBar | ReadMoreComponent;

/**
 * 
 * @export
 * @interface HomeDetail
 */
export interface HomeDetail {
    /**
     * 
     * @type {string}
     * @memberof HomeDetail
     */
    'AboutDescription'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HomeDetail
     */
    'ImagePath'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HomeDetail
     */
    'Language'?: string;
    /**
     * 
     * @type {string}
     * @memberof HomeDetail
     */
    'Slogan'?: string;
}
/**
 * 
 * @export
 * @interface MongoId
 */
export interface MongoId {
    /**
     * Document ID
     * @type {string}
     * @memberof MongoId
     */
    '$oid'?: string;
}
/**
 * 
 * @export
 * @interface NavBar
 */
export interface NavBar {
    /**
     * 
     * @type {string}
     * @memberof NavBar
     */
    'Language'?: string;
    /**
     * 
     * @type {string}
     * @memberof NavBar
     */
    'NavbarAbout'?: string;
    /**
     * 
     * @type {string}
     * @memberof NavBar
     */
    'NavbarContact'?: string;
    /**
     * 
     * @type {string}
     * @memberof NavBar
     */
    'NavbarHome'?: string;
    /**
     * 
     * @type {string}
     * @memberof NavBar
     */
    'NavbarProject'?: string;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'Area'?: Array<string>;
    /**
     * The product\'s name.
     * @type {number}
     * @memberof Project
     */
    'Completion'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'DecorationMaterial'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'ImagePath'?: Array<string>;
    /**
     * The project\'s image url
     * @type {string}
     * @memberof Project
     */
    'ProfileImage'?: string;
    /**
     * ID defined for project
     * @type {string}
     * @memberof Project
     */
    'ProjectId'?: string;
    /**
     * The product\'s name.
     * @type {string}
     * @memberof Project
     */
    'ProjectName'?: string;
    /**
     * The product\'s name.
     * @type {string}
     * @memberof Project
     */
    'ProjectOwner'?: string;
    /**
     * The product\'s name.
     * @type {number}
     * @memberof Project
     */
    'ProjectValue'?: number;
    /**
     * The product\'s name.
     * @type {string}
     * @memberof Project
     */
    'Status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Project
     */
    'Structure'?: Array<string>;
    /**
     * Type of product
     * @type {string}
     * @memberof Project
     */
    'Type'?: string;
    /**
     * 
     * @type {MongoId}
     * @memberof Project
     */
    '_id'?: MongoId;
}
/**
 * 
 * @export
 * @interface ProjectCreatePostRequest
 */
export interface ProjectCreatePostRequest {
    /**
     * 
     * @type {Array<any>}
     * @memberof ProjectCreatePostRequest
     */
    'ImagePath'?: Array<any>;
    /**
     * 
     * @type {any}
     * @memberof ProjectCreatePostRequest
     */
    'ImageProfile'?: any;
}
/**
 * 
 * @export
 * @interface ReadMoreComponent
 */
export interface ReadMoreComponent {
    /**
     * 
     * @type {string}
     * @memberof ReadMoreComponent
     */
    'Language'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadMoreComponent
     */
    'Val'?: string;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * user email.
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * password
     * @type {string}
     * @memberof User
     */
    'password'?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ---
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ---
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterPost: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ---
         * @param {number} schema 
         * @param {string} language 
         * @param {string} [gwAccessToken] an authorization header
         * @param {ComponentsSchemaImageLanguagePostRequest} [componentsSchemaImageLanguagePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsSchemaImageLanguagePost: async (schema: number, language: string, gwAccessToken?: string, componentsSchemaImageLanguagePostRequest?: ComponentsSchemaImageLanguagePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('componentsSchemaImageLanguagePost', 'schema', schema)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('componentsSchemaImageLanguagePost', 'language', language)
            const localVarPath = `/components/{schema}/image/{language}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gwAccessToken !== undefined && gwAccessToken !== null) {
                localVarHeaderParameter['gw-access-token'] = String(gwAccessToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(componentsSchemaImageLanguagePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ---
         * @param {number} schema 
         * @param {string} language 
         * @param {string} [gwAccessToken] an authorization header
         * @param {ComponentsSchemaImageLanguagePostRequest} [componentsSchemaImageLanguagePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsSchemaLanguagePost: async (schema: number, language: string, gwAccessToken?: string, componentsSchemaImageLanguagePostRequest?: ComponentsSchemaImageLanguagePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('componentsSchemaLanguagePost', 'schema', schema)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('componentsSchemaLanguagePost', 'language', language)
            const localVarPath = `/components/{schema}/{language}`
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gwAccessToken !== undefined && gwAccessToken !== null) {
                localVarHeaderParameter['gw-access-token'] = String(gwAccessToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(componentsSchemaImageLanguagePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ---
         * @param {string} [gwAccessToken] an authorization header
         * @param {ProjectCreatePostRequest} [projectCreatePostRequest] Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreatePost: async (gwAccessToken?: string, projectCreatePostRequest?: ProjectCreatePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gwAccessToken !== undefined && gwAccessToken !== null) {
                localVarHeaderParameter['gw-access-token'] = String(gwAccessToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCreatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ---
         * @param {string} gwAccessToken an authorization header
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectIdDelete: async (gwAccessToken: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gwAccessToken' is not null or undefined
            assertParamExists('projectDeleteProjectIdDelete', 'gwAccessToken', gwAccessToken)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectDeleteProjectIdDelete', 'projectId', projectId)
            const localVarPath = `/project/delete/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gwAccessToken !== undefined && gwAccessToken !== null) {
                localVarHeaderParameter['gw-access-token'] = String(gwAccessToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ---
         * @param {string} gwAccessToken an authorization header
         * @param {string} projectId 
         * @param {Project} [project] Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdatePut: async (gwAccessToken: string, projectId: string, project?: Project, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gwAccessToken' is not null or undefined
            assertParamExists('projectUpdatePut', 'gwAccessToken', gwAccessToken)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectUpdatePut', 'projectId', projectId)
            const localVarPath = `/project/update`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gwAccessToken !== undefined && gwAccessToken !== null) {
                localVarHeaderParameter['gw-access-token'] = String(gwAccessToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ---
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginPost(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ---
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRegisterPost(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRegisterPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ---
         * @param {number} schema 
         * @param {string} language 
         * @param {string} [gwAccessToken] an authorization header
         * @param {ComponentsSchemaImageLanguagePostRequest} [componentsSchemaImageLanguagePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentsSchemaImageLanguagePost(schema: number, language: string, gwAccessToken?: string, componentsSchemaImageLanguagePostRequest?: ComponentsSchemaImageLanguagePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentsSchemaImageLanguagePost(schema, language, gwAccessToken, componentsSchemaImageLanguagePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ---
         * @param {number} schema 
         * @param {string} language 
         * @param {string} [gwAccessToken] an authorization header
         * @param {ComponentsSchemaImageLanguagePostRequest} [componentsSchemaImageLanguagePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentsSchemaLanguagePost(schema: number, language: string, gwAccessToken?: string, componentsSchemaImageLanguagePostRequest?: ComponentsSchemaImageLanguagePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentsSchemaLanguagePost(schema, language, gwAccessToken, componentsSchemaImageLanguagePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ---
         * @param {string} [gwAccessToken] an authorization header
         * @param {ProjectCreatePostRequest} [projectCreatePostRequest] Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreatePost(gwAccessToken?: string, projectCreatePostRequest?: ProjectCreatePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreatePost(gwAccessToken, projectCreatePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ---
         * @param {string} gwAccessToken an authorization header
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProjectIdDelete(gwAccessToken: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProjectIdDelete(gwAccessToken, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ---
         * @param {string} gwAccessToken an authorization header
         * @param {string} projectId 
         * @param {Project} [project] Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdatePut(gwAccessToken: string, projectId: string, project?: Project, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdatePut(gwAccessToken, projectId, project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary ---
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost(user?: User, options?: any): AxiosPromise<Token> {
            return localVarFp.authLoginPost(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ---
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterPost(user?: User, options?: any): AxiosPromise<void> {
            return localVarFp.authRegisterPost(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ---
         * @param {number} schema 
         * @param {string} language 
         * @param {string} [gwAccessToken] an authorization header
         * @param {ComponentsSchemaImageLanguagePostRequest} [componentsSchemaImageLanguagePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsSchemaImageLanguagePost(schema: number, language: string, gwAccessToken?: string, componentsSchemaImageLanguagePostRequest?: ComponentsSchemaImageLanguagePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.componentsSchemaImageLanguagePost(schema, language, gwAccessToken, componentsSchemaImageLanguagePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ---
         * @param {number} schema 
         * @param {string} language 
         * @param {string} [gwAccessToken] an authorization header
         * @param {ComponentsSchemaImageLanguagePostRequest} [componentsSchemaImageLanguagePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsSchemaLanguagePost(schema: number, language: string, gwAccessToken?: string, componentsSchemaImageLanguagePostRequest?: ComponentsSchemaImageLanguagePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.componentsSchemaLanguagePost(schema, language, gwAccessToken, componentsSchemaImageLanguagePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ---
         * @param {string} [gwAccessToken] an authorization header
         * @param {ProjectCreatePostRequest} [projectCreatePostRequest] Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreatePost(gwAccessToken?: string, projectCreatePostRequest?: ProjectCreatePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.projectCreatePost(gwAccessToken, projectCreatePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ---
         * @param {string} gwAccessToken an authorization header
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectIdDelete(gwAccessToken: string, projectId: string, options?: any): AxiosPromise<object> {
            return localVarFp.projectDeleteProjectIdDelete(gwAccessToken, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ---
         * @param {string} gwAccessToken an authorization header
         * @param {string} projectId 
         * @param {Project} [project] Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdatePut(gwAccessToken: string, projectId: string, project?: Project, options?: any): AxiosPromise<void> {
            return localVarFp.projectUpdatePut(gwAccessToken, projectId, project, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary ---
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public authLoginPost(user?: User, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).authLoginPost(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ---
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public authRegisterPost(user?: User, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).authRegisterPost(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ---
     * @param {number} schema 
     * @param {string} language 
     * @param {string} [gwAccessToken] an authorization header
     * @param {ComponentsSchemaImageLanguagePostRequest} [componentsSchemaImageLanguagePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public componentsSchemaImageLanguagePost(schema: number, language: string, gwAccessToken?: string, componentsSchemaImageLanguagePostRequest?: ComponentsSchemaImageLanguagePostRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).componentsSchemaImageLanguagePost(schema, language, gwAccessToken, componentsSchemaImageLanguagePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ---
     * @param {number} schema 
     * @param {string} language 
     * @param {string} [gwAccessToken] an authorization header
     * @param {ComponentsSchemaImageLanguagePostRequest} [componentsSchemaImageLanguagePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public componentsSchemaLanguagePost(schema: number, language: string, gwAccessToken?: string, componentsSchemaImageLanguagePostRequest?: ComponentsSchemaImageLanguagePostRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).componentsSchemaLanguagePost(schema, language, gwAccessToken, componentsSchemaImageLanguagePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ---
     * @param {string} [gwAccessToken] an authorization header
     * @param {ProjectCreatePostRequest} [projectCreatePostRequest] Optional description in *Markdown*
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public projectCreatePost(gwAccessToken?: string, projectCreatePostRequest?: ProjectCreatePostRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).projectCreatePost(gwAccessToken, projectCreatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ---
     * @param {string} gwAccessToken an authorization header
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public projectDeleteProjectIdDelete(gwAccessToken: string, projectId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).projectDeleteProjectIdDelete(gwAccessToken, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ---
     * @param {string} gwAccessToken an authorization header
     * @param {string} projectId 
     * @param {Project} [project] Optional description in *Markdown*
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public projectUpdatePut(gwAccessToken: string, projectId: string, project?: Project, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).projectUpdatePut(gwAccessToken, projectId, project, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ---
         * @param {string} language language-code
         * @param {number} schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsSchemaLanguageGet: async (language: string, schema: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('componentsSchemaLanguageGet', 'language', language)
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('componentsSchemaLanguageGet', 'schema', schema)
            const localVarPath = `/components/{schema}/{language}`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)))
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ---
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectLanguageDataGet: async (language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('projectLanguageDataGet', 'language', language)
            const localVarPath = `/project/{language}/data`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ---
         * @param {string} language 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectLanguageProjectIdGet: async (language: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('projectLanguageProjectIdGet', 'language', language)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectLanguageProjectIdGet', 'projectId', projectId)
            const localVarPath = `/project/{language}/{project_id}`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ---
         * @param {string} language language-code
         * @param {number} schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentsSchemaLanguageGet(language: string, schema: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComponentsSchemaLanguageGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentsSchemaLanguageGet(language, schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ---
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectLanguageDataGet(language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectLanguageDataGet(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ---
         * @param {string} language 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectLanguageProjectIdGet(language: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectLanguageProjectIdGet(language, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary ---
         * @param {string} language language-code
         * @param {number} schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsSchemaLanguageGet(language: string, schema: number, options?: any): AxiosPromise<ComponentsSchemaLanguageGet200Response> {
            return localVarFp.componentsSchemaLanguageGet(language, schema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ---
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectLanguageDataGet(language: string, options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.projectLanguageDataGet(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ---
         * @param {string} language 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectLanguageProjectIdGet(language: string, projectId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.projectLanguageProjectIdGet(language, projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary ---
     * @param {string} language language-code
     * @param {number} schema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public componentsSchemaLanguageGet(language: string, schema: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).componentsSchemaLanguageGet(language, schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ---
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectLanguageDataGet(language: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectLanguageDataGet(language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ---
     * @param {string} language 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectLanguageProjectIdGet(language: string, projectId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectLanguageProjectIdGet(language, projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


