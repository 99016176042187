import { useNavigate } from "react-router-dom";
import "../assets/styles/ImageGallery.scss"
import { useEffect } from "react";
import { Project } from "../api/generated";


interface props {
    projects: Project[]
}

let GRIDSIZE = 200;

const ImageGallery = ({ projects }: props) => {
    const navigate = useNavigate()
    let widthGrid = Math.floor((window.innerWidth - 16) / GRIDSIZE);
    let heightGrid = Math.floor((window.innerHeight) / GRIDSIZE);

    function fillMetrix(baseGrid: boolean[][], height: number, width: number) {
        let currentGrid: boolean[][] = baseGrid
        for (let i = 0; i < heightGrid; i++) {
            for (let j = 0; j < widthGrid; j++) {
                if (!currentGrid[i][j] === true) {
                    for (let currentHeight = 0; currentHeight < height; currentHeight++) {
                        for (let currentWidth = 0; currentWidth < width; currentWidth++) {
                            currentGrid[i + currentHeight][j + currentWidth] = true
                        }
                    }
                    return currentGrid
                }
            }
        }
        return Array(heightGrid).fill(false).map(() => Array(widthGrid).fill(false))
    }

    function getRandomInt(min: number, max: number): number {
        if (max < min) {
            return max
        }
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function randomShape(baseGrid: boolean[][]): number[] {
        let max: number[] = findPossilbHeightWidth(baseGrid)
        let height: number = getRandomInt(2, max[0]);
        let width: number = getRandomInt(2, max[1]);
        return [height, width]
    }

    function findPossilbHeightWidth(baseGrid: boolean[][]): number[] {
        let possibleHeight: number = 0;
        let possibleWidth: number = 0;
        for (let i = 0; i < heightGrid; i++) {
            for (let j = 0; j < widthGrid; j++) {
                if (!baseGrid[i][j]) {
                    let widthCount: number = 1
                    for (let k = j + 1; k < widthGrid; k++) {
                        if (!baseGrid[i][k]) {
                            widthCount++
                        }
                        else {
                            break;
                        }
                    }
                    if (widthCount === 1) {
                        return [1, 1]
                    }
                    possibleWidth = widthCount
                    while (true) {
                        if (i === heightGrid - 1) {
                            return [1, 1]
                        }
                        if (j === 0 ? !baseGrid[i][j] : !baseGrid[i][j - 1]) {
                            possibleHeight = heightGrid - i
                            return [possibleHeight > 3 ? 3 : possibleHeight, possibleWidth > 3 ? 3 : possibleWidth]
                        }
                        i++
                    }
                }
            }
        }
        return [0, 0]
    }

    function listOfShape(range: number): number[][] {
        var baseGrid: boolean[][] = Array(widthGrid).fill(false).map(() => Array(heightGrid).fill(false))
        let result: number[][] = []
        for (let i = 0; i < range; i++) {
            var max: number[] = randomShape(baseGrid);
            if (JSON.stringify(max) === JSON.stringify([0, 0])) {
                baseGrid = Array(widthGrid).fill(false).map(() => Array(heightGrid).fill(false))
                max = randomShape(baseGrid);
            }
            result.push(max)
            baseGrid = fillMetrix(baseGrid, max[0], max[1])
        }
        return result
    }

    function generateHTML([h, v]: any, id: number) {
        const imgSrc = projects[id].ProfileImage ?? ""

        const innerSecondChild = document.createElement("p")
        innerSecondChild.className = "text-xl text-white z-99"
        innerSecondChild.textContent = projects[id].ProjectName ?? ""

        const secondChild = document.createElement("div")
        secondChild.className = `item__overlay height-${v}`
        secondChild.appendChild(innerSecondChild)

        const firstChild = document.createElement("img")
        firstChild.className = "mainImg"
        firstChild.src = imgSrc

        const mainChild = document.createElement("div")
        mainChild.className = `item h${h} v${v}`
        mainChild.id = `${id}`

        mainChild.appendChild(firstChild)

        if ((Number(h) === 3 && Number(v) !== 3) || (Number(v) === 3 && Number(h) !== 3)) {
            mainChild.className = `span-item h${h} v${v}`
            const spanImg = document.createElement("div")
            spanImg.className = "spanImg"
            spanImg.style.width = `${3 * GRIDSIZE}px`
            spanImg.style.height = `${3 * GRIDSIZE}px`

            const spanHover = document.createElement("div")
            spanHover.className = "spanOverlay"
            spanHover.style.width = `${3 * GRIDSIZE}px`
            spanHover.style.height = `${3 * GRIDSIZE}px`
            spanHover.appendChild(innerSecondChild)

            const thirdChild = document.createElement("img")
            thirdChild.className = `popup h${h} v${v}`
            thirdChild.src = imgSrc
            thirdChild.style.minWidth = `0`
            thirdChild.style.minHeight = `0`

            spanImg.append(thirdChild)
            spanImg.append(spanHover)
            mainChild.appendChild(spanImg)
        }
        else {
            mainChild.appendChild(secondChild)
        }

        mainChild.onclick = function () {
            navigate(`/projects/${id}`)
        }
        return mainChild
    }

    // function randomNumber(limit: number) {
    //     return Math.floor(Math.random() * limit) + 1;
    // }

    function createChild(data: number[][]) {
        if (data.length < 1) {
            return
        }
        for (var i = 0; i < data.length; i++) {
            const parent = document.querySelector("#gallery")
            parent?.appendChild(generateHTML(data[i], i))
        }
    }


    useEffect(() => {
        let data = listOfShape(projects.length);
        createChild(data)
    }, [projects.length])

    return (
        <>
            {projects.length > 0 ?
                <div id="imgGallery" className=' w-full h-max overflow-scroll'>
                    <section id="gallery" className="gallery justify-center h-max">
                    </section>
                </div> :

                <div className="w-full h-full flex justify-center items-center">
                    <div className="w-fit text-red-500">
                        NO PROJECT AVAILABLE.
                    </div>
                </div>
            }
        </>

    )
}

export default ImageGallery