import { HomeStore } from './HomeStore';
import { ProjectDetailStore } from './ProjectDetailStore';
import { ProjectStore } from './ProjectStore';
import { LanguageStore } from './LanguageStore';
import { NavBarStore } from './NavBarStore';
import { AboutUsStore } from './AboutUsStore';

export interface IRootStore {
  homeStore: HomeStore;
  projectStore: ProjectStore;
  projectDetailStore: ProjectDetailStore;
  languageStore: LanguageStore;
  navBarStore: NavBarStore;
  aboutUsStore: AboutUsStore;
}

class RootStore implements IRootStore {
  constructor() {
    this.homeStore = new HomeStore(this);
    this.projectStore = new ProjectStore(this);
    this.projectDetailStore = new ProjectDetailStore(this);
    this.languageStore = new LanguageStore(this);
    this.navBarStore = new NavBarStore(this);
    this.aboutUsStore = new AboutUsStore(this);
  }
  navBarStore: NavBarStore;
  projectDetailStore: ProjectDetailStore;
  homeStore: HomeStore;
  projectStore: ProjectStore;
  languageStore: LanguageStore;
  aboutUsStore: AboutUsStore;
}
export const rootStore = new RootStore();
