import { ProjectData } from '../models/Project';
import ProjectDescriptionCard from './ProjectDescriptionCard';

interface IProjectDescriptionSection {
  project: ProjectData;
}

const ProjectDescriptionSection: React.FC<IProjectDescriptionSection> = ({
  project,
}) => {
  const getDescription = (str: string | string[] | number): string => {
    str = String(str);
    if (str !== undefined && str !== null && str !== '' && str !== '0') {
      return str;
    }
    return 'NA';
  };

  const descriptionRow = (
    header1: string,
    header2: string,
    description1: string | string[] | number,
    description2: string | string[] | number,
    showUnderLine: boolean
  ) => {
    return (
      <>
        <div className="grid md:grid-cols-2">
          <ProjectDescriptionCard
            header={header1}
            description={getDescription(description1)}
          />
          {header2 !== '' && (
            <>
              <hr className="h-0.5 bg-black border-black md:hidden"></hr>
              <ProjectDescriptionCard
                header={header2}
                description={getDescription(description2)}
              />
            </>
          )}
        </div>
        {showUnderLine && <hr className="h-0.5 bg-black border-black"></hr>}
      </>
    );
  };

  console.log(project)

  return (
    <div className="relative ml-auto mr-auto bg-[#1B1B1B]">
      <div className="w-fit ml-auto mr-auto content-center">
        {descriptionRow(
          'Project name',
          'Area',
          project.ProjectName,
          project.Area,
          true
        )}
        {descriptionRow(
          'Design',
          'Project owner',
          'Gateway Achitects',
          project.ProjectOwner,
          true
        )}
        {descriptionRow(
          'Project value',
          'Type',
          project.ProjectValue.toString(),
          project.Type,
          true
        )}
        {descriptionRow('Structure', 'Decoration', project.Structure.join(', '), project.DecorationMaterial.join(', '), true)}
        {descriptionRow('Completion', 'Status', project.Completion.toString(), project.Status, false)}
      </div>
    </div>
  );
};

export default ProjectDescriptionSection;
