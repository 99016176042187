import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { motion } from 'framer-motion';
import ImageGallery from 'react-image-gallery'
import FullscreenBackground from '../components/FullscreenBackground';
import ProjectDescriptionSection from '../components/ProjectDescriptionSection';

import '../styles/ProjectDescription.scss'
import { ProjectData } from '../models/Project';
import axios from 'axios';


const images = [
  {
    original: 'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80',
    thumbnail: 'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',

  },
];

const ProjectDescription = () => {
  // const { project, fetchProject } = rootStore.projectDetailStore!;
  const { projectId } = useParams();
  const [isShowGalleryNav, setShowGalleryNav] = useState(false);
  const [project, setProject] = useState<ProjectData>()
  const [loading, setLoading] = useState<boolean>(true)

  const handleResize = () => {
    if (window.innerWidth < 816) {
      setShowGalleryNav(false)
    } else {
      setShowGalleryNav(true);
    }
  }

  const getData = async () => {
    try {
      const res = await axios.get(`http://127.0.0.1:8000/project/en-us/${projectId}`);
      setProject(res.data)
      setLoading(false)
    }
    catch (e) {
      alert("Failed to fetch data.")
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    // if (project.id !== projectId) {
    //   fetchProject(String(projectId));
    // }
    handleResize();
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  });

  function convertToThumnail(images: string[]) {
    const converted = images.map((item) => ({
      original: item,
      thumbnail: item
    }))
    return converted
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {loading ? <div></div> : <>
        <div className="relative">
          <div
            id="header"
            className="absolute right-0 bottom-0 z-20 text-white md:text-[10vw] text-[15vw]"
          >
            {project?.ProjectName}
          </div>
          <FullscreenBackground imgUrl={[project!.ProfileImage]} duration={0} isSlideShowActive={false} />
        </div>
        <div className="bg-[#1B1B1B] pb-5">
          <ProjectDescriptionSection project={project!} />
          <div className='bg-[#202020] pt-16 pb-6'>
            <ImageGallery items={convertToThumnail(project!.ImagePath)} thumbnailPosition={"bottom"} showPlayButton={false} showNav={isShowGalleryNav} autoPlay={true} showFullscreenButton={false} slideDuration={600} />
          </div>
        </div></>}
    </motion.div>
  );
};

export default inject('rootStore')(observer(ProjectDescription));
