import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavigationBar from './layouts/navbar';
import Projects from './routes/Projects';
import { Provider } from 'mobx-react';
import Home from './routes/Home';
import AboutUs from './routes/AboutUs';
import Contact from './routes/Contact';
import { rootStore } from './stores/RootStore';
import ProjectDescription from './routes/ProjectDescription';
import { AnimatePresence } from 'framer-motion';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AnimatedRoutes = () => {

  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/projects" element={<Projects />}></Route>
        <Route
          path="/projects/:projectId"
          element={<ProjectDescription />}
        ></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
    </Routes>
    </AnimatePresence>
    
  )
}

root.render(
    <BrowserRouter>
      <div className="h-screen w-screen relative">
        <Provider {...{ rootStore }}>
          <NavigationBar />
          <AnimatedRoutes />
        </Provider>
      </div>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
