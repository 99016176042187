import { useEffect, useState } from 'react';
import "../styles/FullscreenBackground.scss"
export interface IFullscreenBackground {
  imgUrl: string[];
  duration: number;
  isSlideShowActive: boolean;
}

const FullscreenBackground: React.FC<IFullscreenBackground> = ({ imgUrl, duration, isSlideShowActive }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    if (isSlideShowActive) {
      setTimeout(()=> {
        if (currentIndex >= imgUrl.length - 1) {
          setCurrentIndex(0)
        } else {
          setCurrentIndex(currentIndex + 1)
        }
        
      }, duration * 1000)
    }
  })

  return (
    <div className="w-full pt-14 relative z-0 h-[100vh] flex overflow-hidden">
      {imgUrl.map((img, index) => {
        return (
        <img
        key={index}
        id = {index === currentIndex ? "image-active": "image-deactive"}
        className={"object-cover h-full w-full absolute"}
        src={img}
        alt="Background"
        />)
      })}
    </div>
  );
};

export default FullscreenBackground;
