import { Transition } from 'react-transition-group';
import { inject, observer } from 'mobx-react';
import { useEffect, useState, CSSProperties } from 'react';
import { Direction } from '../utils/hooks/scroll/constant/constant';
import useScrollDirection from '../utils/hooks/scroll/useScrollDirection';
import { rootStore } from '../stores/RootStore';
import { motion } from 'framer-motion';

import '../styles/Home.scss';
import FullscreenBackground from '../components/FullscreenBackground';
import { Link } from 'react-router-dom';

const Home = () => {
  const { languageStore, convertLanguage } = rootStore.languageStore!
  const { homeViewModel, getReadMoreText, fetchHomeViewModel } = rootStore.homeStore!
  const [isShowDescription, setShowDescription] = useState(false);
  const { directionUpDown, update, setDirectionUpDown } =
    useScrollDirection(10);

  useEffect(() => {
    getHomePageData()
  }, [languageStore]);


  const getHomePageData = async() => {
    fetchHomeViewModel(convertLanguage(languageStore))
  }

  useEffect(() => {
    if (directionUpDown === Direction.Down) {
      setShowDescription(true);
    } else if (directionUpDown === Direction.Up) {
      setShowDescription(false);
    }
  }, [directionUpDown]);

  const defaultStyles = {
    transition: `opacity 300ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles: { [x: string]: CSSProperties | undefined } = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const header = (
    <div className="absolute left-5 bottom-3 z-10 pt-20 font-semibold text-white mt-10 mr-2 sm:mr-20">
      <p className="text-[5rem] leading-[4.5rem] ">
        GATEWAY ARCHITECTS
      </p>
      <hr
        style={{
          color: '#DE4B52',
          backgroundColor: '#DE4B52',
          border: 0,
          height: 6,
        }}
      />
      <p className="font-normal text-2xl mt-2">{homeViewModel.Slogan}</p>
    </div>
  );

  const aboutUsTab = (
    <Transition in={isShowDescription} timeout={300}>
      {(state) => (
        <div
          className="absolute w-full z-20 bottom-0 bg-white"
          style={{
            ...defaultStyles,
            ...transitionStyles[state],
          }}
        >
          {closeDescriptionButton}
          <div className="flex pb-8 mt-4 mx-5 sm:mx-10">
            <div className="border-l-6 mr-20 h-{100%} border-gateway-red-1 hidden sm:block" style={{
            }}>
            </div>
            <div>
              <p className="mb-4 text-md sm:text-lg">{homeViewModel.AboutDescription}</p>
              <Link className="text-2xl text-blue-button-1" to="/about-us">{getReadMoreText(languageStore)}</Link>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );

  const toggleShowDescription = () => {
    setShowDescription(!isShowDescription);
    if (isShowDescription) {
      setDirectionUpDown(Direction.Up);
    } else {
      setDirectionUpDown(Direction.Down);
    }
  };

  const openDescriptionButton = (
    <div className="absolute bottom-0 left-0 right-0 z-30 ml-auto mr-auto text-center">
      <button onClick={toggleShowDescription} disabled={isShowDescription}>
        <img
          src="/whiteArrowIcon.svg"
          className="w-10 h-10"
          alt="open-button"
        />
      </button>
    </div>
  );

  const closeDescriptionButton = (
    <div className="relative ml-auto mr-auto text-center">
      <button onClick={toggleShowDescription} disabled={!isShowDescription}>
        <img
          src="/blackArrowIcon.svg"
          className="w-10 h-10"
          alt="close-button"
        />
      </button>
    </div>
  );
  return (
    <motion.div
      className="relative h-[100vh]"
      onWheel={(event) => {
        update(event);
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      
      {header}
      {openDescriptionButton}
      {aboutUsTab}
      <FullscreenBackground imgUrl={homeViewModel.ImagePath ?? []} duration={10} isSlideShowActive={true} />
    </motion.div>
  );
};

export default inject('rootStore')(observer(Home));
