export interface IProjectDescriptionCard {
  header: string;
  description: string;
}

const ProjectDescriptionCard: React.FC<IProjectDescriptionCard> = ({
  header,
  description,
}) => {
  return (
    <div className="grid gap-4 grid-cols-2 my-10 mx-10">
      <div className="w-fit text-white text-xl">{header}</div>
      <div className="w-fit text-white text-xl font-light">{description}</div>
    </div>
  );
};

export default ProjectDescriptionCard;
