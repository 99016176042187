import { action, makeObservable, observable } from 'mobx';
import { IProjectDetail, ProjectDetail } from '../models/ProjectDetail';
import { IRootStore } from './RootStore';

export class ProjectDetailStore {
  @observable project: IProjectDetail = new ProjectDetail('', '');
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  async fetchProject(id: string): Promise<void> {
    this.project = new ProjectDetail('Number 1', '1');
  }
}
