import { action, makeObservable, observable, runInAction } from 'mobx';
import { IRootStore } from './RootStore';
import { Project } from '../api/generated';
import { api } from '../api';

export class ProjectStore {
  @observable projects: Project[] = [];
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  async fetchProjects(): Promise<void> {
    const response = await api.projectLanguageDataGet(this.rootStore.languageStore.convertLanguage(this.rootStore.languageStore.languageStore))
    runInAction(() => {
      this.projects = response.data
    })
  }

  @action.bound
  async getOneProject(projectId: string): Promise<Project | null> {
    if (this.projects.length === 0) {
      await this.fetchProjects();
    }
    if (this.projects.length > 0) {
      var result = this.projects.filter((project: Project) => {
        return project === projectId;
      });
      if (result.length > 0) {
        return result[0];
      }
    }
    return null;
  }

  @action.bound
  async assignImageRatio(): Promise<void> {}
}
