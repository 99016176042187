export interface IProjectDetail {
  id: string;
  ProjectName: string;
  ProjectOwner: string;
  Area: string[];
  Type: string[];
  ProfileImage: string;
  ImagePath: string[];
  ProjectValue: number;
  Completion: number;
  Structure: string[];
  DecorationMaterial: string[];
  Status: string;
}

export class ProjectDetail implements IProjectDetail {
  id: string;
  ProjectName: string;
  ProjectOwner: string;
  Area: string[];
  Type: string[];
  ProfileImage: string;
  ImagePath: string[];
  ProjectValue: number;
  Completion: number;
  Structure: string[];
  DecorationMaterial: string[];
  Status: string;

  constructor(name: string, id: string) {
    this.ProjectName = name;
    this.id = id;
    this.ProjectOwner = 'James';
    this.Area = [];
    this.Type = [];
    this.ProfileImage = '';
    this.ImagePath = [];
    this.ProjectValue = 10000;
    this.Completion = 0;
    this.Structure = [];
    this.DecorationMaterial = [];
    this.Status = '';
  }
}
