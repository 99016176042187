import { useState } from 'react';
import { Direction } from './constant/constant';

const useScrollDirection = (thereshold: number) => {
  const [directionUpDown, setDirectionUpDown] = useState<Direction>(
    Direction.NotMove
  );
  const [directionLeftRight, setDirectionLeftRight] = useState<Direction>(
    Direction.NotMove
  );

  const update = (event: React.WheelEvent<Element>) => {
    if (event.nativeEvent.deltaY > thereshold) {
      setDirectionUpDown(Direction.Down);
    } else if (event.nativeEvent.deltaY < -1 * thereshold) {
      setDirectionUpDown(Direction.Up);
    }
    if (event.nativeEvent.deltaX > thereshold) {
      setDirectionLeftRight(Direction.Right);
    } else if (event.nativeEvent.deltaX < -1 * thereshold) {
      setDirectionLeftRight(Direction.Left);
    }
  };

  return {
    directionUpDown,
    directionLeftRight,
    update,
    setDirectionUpDown,
    setDirectionLeftRight,
  };
};

export default useScrollDirection;
