import { action, makeObservable, observable } from 'mobx';
import { IRootStore } from './RootStore';

export class LanguageStore {
  @observable languageStore: string = "EN"
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  @action.bound
  setLanguageStore(language: string) {
    if (language !== this.languageStore) {
      this.languageStore = language
    }
  }

  convertLanguage(language: string) {
    switch(language) {
      case "TH": return "th-th"
      default: return "en-us"
    }
  }
}