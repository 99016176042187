import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import '../assets/styles/Contact.scss';

const Contact = () => {
  const [advertiseText, setAdvertiseText] = useState<string>('');
  const [firstHeading, setFirstHeading] = useState<string>('');
  const [secondHeading, setSecondHeading] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [contactButton, setContactButton] = useState<string>('');
  const [mapMarkerHeading, setMapMarkerHeading] = useState<string>('');
  const [mapMarkerImageUrl, setMapMarkerImageUrl] = useState<string>('');
  const [address, setAddress] = useState<string>('');

  useEffect(() => {
    setAdvertiseText(`We looking forward\nto working with you`);
    setFirstHeading('Contact');
    setSecondHeading('Information');
    setName('GATEWAY ARCHITECTS');
    setPhone('088-888-8888');
    setEmail('samplemail@gmail.com');
    setContactButton('CONTACT US');
    setMapMarkerHeading('Find us');
    setMapMarkerImageUrl(
      'https://wp-assets.infinum.com/uploads/2022/03/creating-custom-markers-on-google-maps-in-flutter-apps-1.png'
    );
    setAddress('1234 Sample Street Bangkok 787787');
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="h-[100vh] w-full relative overflow-hidden" >
        <img className='absolute z-[-1] opacity-10 '  src='/images/ContactBackground.png' alt="" />
        <div className='grid grid-cols-2 h-full'>
          <div
            className="w-full h-[45vh flex justify-center items-center font-semibold text-5xl text-center"
            style={{ whiteSpace: 'pre-line' }}
          >
            {advertiseText}
          </div>
          <div className="w-full h-[45vh] mt-[8vh] flex justify-center items-center">
            <div className='grid'>
              <div className="grid text-center">
                <div className="text-gray-400 text-4xl font-light">
                  {firstHeading}
                </div>
                <div className="text-2xl font-bold">{secondHeading}</div>
              </div>
              <div className="text-lexend-peta">{name}</div>
              <div className="font-bold text-center">{phone}</div>
              <div className="text-gray-600 font-light text-center">{email}</div>
              <div className="bg-black text-white py-3 px-12 mt-2">
                <div className="font-normal text-xs text-center">{contactButton}</div>
              </div>
            </div>

          </div>
          <div className="w-full h-[37vh] flex justify-center">
            <img
              src={mapMarkerImageUrl}
              alt="Map"
              className="object-cover border border-2 border-gray-300 rounded-lg"
            />
          </div>
          <div className="flex flex-col justify-center p-3">
            <div className="font-bold text-3xl text-center">{mapMarkerHeading} </div>
            <div className="font-normal text-3xl text-center">{address}</div>
          </div>
        </div>

      </div>
    </motion.div>
  );
};

export default Contact;
