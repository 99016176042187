import { useEffect, useState, Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { rootStore } from '../stores/RootStore';
import { inject, observer } from 'mobx-react';

const NavigationBar = () => {
  const [currentLanguage, setLanguage] = useState<string>('');
  const [allLanguages, setAllLanguages] = useState<string[]>([]);

  const { navBarViewModel, fetchNavBarViewModel } = rootStore.navBarStore!
  const { setLanguageStore, languageStore, convertLanguage } = rootStore.languageStore!
  const location = useLocation();

  useEffect(() => {
    const mockLanguage: string[] = ['EN', 'TH'];
    setAllLanguages(mockLanguage);
    setLanguage('EN');
  }, []);

  useEffect(() => {
    fetchNavBarViewModel(convertLanguage(languageStore));
  }, [languageStore])

  const setShowLanguage = (language: string) => {
    setLanguage(language)
    setLanguageStore(language)
  }

  return (
    <nav
      className={
        'z-[99] w-screen px-5 py-2 fixed  h-[8vh] flex justify-between transition duration-300 bg-white shadow-md'
      }
    >
      <div className="w-28 h-full grid place-content-center ">
        <div>Logo</div>
      </div>
      <div className="h-full grow flex justify-end gap-14 items-center pr-24 text-center text-sm">
        <NavLink to="/">
          <div className={location.pathname === "/" ? "text-[#c82538]": ""} id="home">{navBarViewModel.NavbarHome}</div>
        </NavLink>
        <NavLink to="/about-us">
          <div className={location.pathname === "/about-us" ? "text-[#c82538]": ""} id="about-us">{navBarViewModel.NavbarAbout}</div>
        </NavLink>
        <NavLink to="/projects">
          <div className={location.pathname.startsWith("/projects") ? "text-[#c82538]": ""} id="project">{navBarViewModel.NavbarProject}</div>
        </NavLink>
        <NavLink to="/contact">
          <div className={location.pathname === "/contact" ? "text-[#c82538]": ""} id="contact">{navBarViewModel.NavbarContact}</div>
        </NavLink>
      </div>
      <div className="w-28 h-full grid place-content-center">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="flex justify-center items-center w-full rounded-md px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 transition duration-300">
              <div>{currentLanguage}</div>
              <ExpandMoreIcon className="-mr-1 ml-1" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="w-full origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {allLanguages.map((language) => {
                  return (
                    <Menu.Item key={language}>
                      {({ active }) => (
                        <span onClick={() => {setShowLanguage(language)}}>
                          <div
                            className={
                              'block px-4 py-2 text-sm ' +
                              (active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700')
                            }
                          >
                            {language}
                          </div>
                        </span>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </nav>
  );
};

export default inject('rootStore')(observer(NavigationBar));
