import { action, makeObservable, observable, runInAction } from 'mobx';
import { AboutUsComponent } from '../api/generated';
import { IRootStore } from './RootStore';
import { api } from '../api';

export class AboutUsStore {
  @observable aboutUsViewModel: AboutUsComponent = {
    Language: '',
    Val: '',
  };
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  async fetchAboutUsComponent(Language: string): Promise<void> {
    try {
      let response = await api.componentsSchemaLanguageGet(Language, 2)
      runInAction(() => {
        this.aboutUsViewModel = response.data
      })
    } catch {

    }
  }
}