import { useEffect, useState } from 'react';
import { rootStore } from '../stores/RootStore';
import { motion } from 'framer-motion';

import '../assets/styles/AboutUs.scss';
import { inject, observer } from 'mobx-react';

const AboutUs = () => {
  const [highlightedImage, setHighlightedImage] = useState<string>('');
  const { aboutUsViewModel, fetchAboutUsComponent } = rootStore.aboutUsStore!
  const { languageStore, convertLanguage } = rootStore.languageStore!

  useEffect(() => {
    setHighlightedImage(
      'https://previews.123rf.com/images/singha103/singha1031509/singha103150900008/45708164-sea-view-background.jpg'
    );
  }, []);

  useEffect(() => {
    fetchAboutUsComponent(convertLanguage(languageStore));
  }, [languageStore]);
  return (
    <motion.div 
      className="pt-[8vh] h-full font-inter" 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <div className="flex h-1/4 bg-black">
        <img
          src={highlightedImage}
          alt="Highlight"
          className="object-cover w-full h-full"
        />
      </div>
      <div
        className="px-12 py-14 font-semibold text-lg"
        style={{ whiteSpace: 'pre-line' }}
      >
        {aboutUsViewModel.Val}
      </div>
    </motion.div>
  );
};

export default inject('rootStore')(observer(AboutUs));
