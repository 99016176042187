import { action, makeObservable, observable, runInAction } from 'mobx';
import { HomeDetail } from '../api/generated';
import { IRootStore } from './RootStore';
import { api } from '../api';
import { IHomeViewModel } from '../models/Home';

export class HomeStore {
  @observable homeViewModel: HomeDetail = {
    Language: '',
    Slogan: '',
    AboutDescription: '',
    ImagePath: []
  };
  rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action.bound
  async fetchHomeViewModel(Language: string): Promise<void> {
    try {
      let response = await api.componentsSchemaLanguageGet(Language, 4)
      runInAction(()=>{
          this.homeViewModel = response.data
      })
  } catch {

  }
  }

  @action.bound
  getReadMoreText(language: string): string {
    return language === "TH" ? "อ่านเพิ่มเติม" : "Read more";
  }
}
